import _ from 'lodash';
import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingContainerWithErrorPanel from '../../../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import FormDialog from '../../../../commons/dialogs/FormDialog';
import { ErrorBar, ProcessingBar, SuccessBar } from '../../../../commons/snackbars/snackbars';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import HasPermission from '../../../../commons/permissions/HasPermission';
import { usePermissions } from '../../../../hooks/usePermissions';
import { DASHBOARD_PATH } from '../../../../routes/paths';
import mainTheme from '../../../../themes/mainTheme';
import { CpoChargePoint, CpoConnector, EmspCharger } from '../../../../types/charger/Charger';
import { ChargerInfoFlowState } from '../../../../types/charger/ChargerInfoState';
import { Permission } from '../../../../types/user/UserPermission';
import { ChargerConnectorsPanel } from './ChargerConnectorsPanel';
import DecommissionChargerForm from './ChargerDecommission/DecommissionChargerForm';
import ChargerInfoPanel from './ChargerInfoPanel';
import { EditChargerConnectorsForm } from './EditChargerConnectorsForm';
import EditChargerInfoForm from './EditChargerInfoForm';
import ResetChargePointForm from './ResetChargerForm';
import useChargerInfo from './hook/useChargerInfo';
import { withChargerInfo } from './hook/withChargerInfo';

const useStyles = makeStyles((theme) => ({
  decommissionButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export interface ChargerInfoPanelProps {
  emspChargerFromParent: EmspCharger;
  cpoChargePointFromParent?: CpoChargePoint;
  handleReload: () => void;
}

export interface UpdateCpoChargePoint {
  cpoChargePointId: string;
  ocppChargePointIdentifier?: string;
  anonymousChargingAllowed?: boolean | string;
}

export interface CpoConnectors {
  connectors: CpoConnector[];
}

const ChargerInfo: FC<ChargerInfoPanelProps> = ({ emspChargerFromParent, cpoChargePointFromParent, handleReload }) => {
  const classes = useStyles(mainTheme);
  const { state, addEvent, ChargerInfoEvent } = useChargerInfo();
  const { hasPermission } = usePermissions();
  const history = useHistory();
  const emspCharger = state.emspCharger;
  const cpoChargePoint = state.cpoChargePoint;

  // FIXME: Find a cleaner way to trigger the child to reload when reloading charger on parent
  useEffect(() => {
    if (state.flowState === ChargerInfoFlowState.INIT || cpoChargePointFromParent !== cpoChargePoint) {
      addEvent({ type: ChargerInfoEvent.VIEW_CHARGER, payload: { emspCharger: emspChargerFromParent, cpoChargePoint: cpoChargePointFromParent } });
    } else if (state.flowState === ChargerInfoFlowState.CHARGER_UPDATED || state.flowState === ChargerInfoFlowState.CONNECTORS_UPDATED) {
      addEvent({ type: ChargerInfoEvent.RELOAD_CHARGER, payload: { emspCharger: emspChargerFromParent, cpoChargePoint: null } });
      handleReload();
    }
  }, [
    state.flowState,
    addEvent,
    emspChargerFromParent,
    cpoChargePointFromParent,
    cpoChargePoint,
    handleReload,
    ChargerInfoEvent.VIEW_CHARGER,
    ChargerInfoEvent.RELOAD_CHARGER
  ]);

  const handleEditClicked = () => {
    addEvent({ type: ChargerInfoEvent.EDIT_CHARGER_REQUESTED });
  };

  const handleCancelAction = () => {
    addEvent({ type: ChargerInfoEvent.CHARGER_ACTION_CANCELLED });
  };

  const handleSubmitEdit = (values: UpdateCpoChargePoint) => {
    addEvent({
      type: ChargerInfoEvent.SUBMIT_EDIT_CHARGER,
      payload: values
    });
  };

  const handleResetClicked = () => {
    addEvent({
      type: ChargerInfoEvent.RESET_CHARGER_REQUESTED
    });
  };

  const handleRemoteStart = () => {
    const url = `${DASHBOARD_PATH}/locations/${emspCharger?.countryCode}/${emspCharger?.partyId}/${emspCharger?.locationId}/chargers/${emspCharger?.displayName}/remote-start`;
    return history.push(url);
  };

  const handleResetCharger = (resetType: string) => {
    addEvent({
      type: ChargerInfoEvent.SUBMIT_RESET_CHARGER,
      payload: { chargerId: state.cpoChargePoint?.id, resetType: resetType }
    });
  };

  const handleConnectorsEditClicked = () => {
    addEvent({ type: ChargerInfoEvent.EDIT_CONNECTORS_REQUESTED });
  };

  const handleSubmitEditConnectors = (values: CpoConnectors) => {
    addEvent({
      type: ChargerInfoEvent.SUBMIT_EDIT_CONNECTORS,
      payload: values
    });
  };

  const handleDecommissionClicked = () => {
    addEvent({ type: ChargerInfoEvent.DECOMMISSION_CHARGER_REQUESTED });
  };

  // we can only reset charger that belongs to Central Server
  // TODO: check if want to use evse status to determine if charger can be reset
  const isCentralServerCharger = !_.isNil(cpoChargePoint);

  const canEditCharger: boolean = hasPermission(Permission.UPDATE_CHARGE_POINT_DETAILS) && !_.isNil(state.cpoChargePoint);

  const canRemoteStart = hasPermission(Permission.REMOTE_START_TRANSACTION) && !_.isNil(state.emspCharger);

  const hasAtLeastOneAvailableEvse = state.emspCharger?.evses?.some((evse) => evse.status.toUpperCase() === 'AVAILABLE');

  const showEditForm =
    state.flowState === ChargerInfoFlowState.SHOW_EDIT_CHARGER ||
    state.flowState === ChargerInfoFlowState.FAILED_TO_UPDATE_CHARGER ||
    state.flowState === ChargerInfoFlowState.UPDATING_CHARGER;

  const showResetForm =
    state.flowState === ChargerInfoFlowState.SHOW_RESET_CHARGER || state.flowState === ChargerInfoFlowState.FAILED_TO_RESET_CHARGER;

  const showInfoPanel = !showEditForm;

  const isOpenloopCharger = emspCharger?.countryCode === 'NZ' && emspCharger?.partyId === 'OPL';
  const canEditConnectors = hasPermission(Permission.UPDATE_CHARGE_POINT_DETAILS) && !_.isNil(emspCharger?.evses);

  const showEditConnectorsForm =
    state.flowState === ChargerInfoFlowState.SHOW_EDIT_CONNECTORS ||
    state.flowState === ChargerInfoFlowState.FAILED_TO_UPDATE_CONNECTORS ||
    state.flowState === ChargerInfoFlowState.UPDATING_CONNECTORS;

  const showDecommissionForm = state.flowState === ChargerInfoFlowState.SHOW_DECOMMISSION_CHARGER_FORM;
  const showViewConnectors = !showEditConnectorsForm;
  const showProcessing = state.flowState === ChargerInfoFlowState.UPDATING_CHARGER || state.flowState === ChargerInfoFlowState.UPDATING_CONNECTORS;
  return (
    <LoadingContainerWithErrorPanel {...state.loadingState}>
      {showInfoPanel && emspCharger && (
        <>
          <ChargerInfoPanel
            cpoChargePoint={cpoChargePoint}
            emspCharger={emspCharger}
            canEdit={canEditCharger}
            handleEdit={handleEditClicked}
            canReset={isCentralServerCharger}
            handleReset={handleResetClicked}
            canRemoteStart={canRemoteStart}
            handleRemoteStart={handleRemoteStart}
            hasAtLeastOneAvailableEvse={hasAtLeastOneAvailableEvse ? true : false}
          />
        </>
      )}
      {showEditForm && cpoChargePoint && (
        <EditChargerInfoForm
          cpoChargePoint={cpoChargePoint}
          handleCancel={handleCancelAction}
          hasFailed={state.flowState === ChargerInfoFlowState.FAILED_TO_UPDATE_CHARGER}
          handleSubmit={handleSubmitEdit}
        />
      )}
      <FormDialog open={showResetForm} onClose={handleCancelAction} title={`Reset Charger`} maxWidth={400} fullPage={false}>
        <ResetChargePointForm hasFailed={state.flowState === ChargerInfoFlowState.FAILED_TO_RESET_CHARGER} handleReset={handleResetCharger} />
      </FormDialog>
      {showViewConnectors && emspCharger && emspCharger.evses && (
        <ChargerConnectorsPanel
          connectors={emspCharger?.evses?.flatMap((evse) => evse.connectors.map((connector) => ({ ...connector, status: evse.status })))}
          canEditConnectors={canEditConnectors && isOpenloopCharger}
          handleEditConnectors={handleConnectorsEditClicked}
        />
      )}

      {showEditConnectorsForm && cpoChargePoint?.evses && (
        <EditChargerConnectorsForm
          evses={cpoChargePoint.evses}
          handleCancel={handleCancelAction}
          hasFailed={state.flowState === ChargerInfoFlowState.FAILED_TO_UPDATE_CONNECTORS}
          handleSubmit={handleSubmitEditConnectors}
        />
      )}

      {isCentralServerCharger && (
        <HasPermission permission={Permission.DECOMMISSION_CHARGE_POINT}>
          <Box className={classes.decommissionButtonContainer}>
            <Button variant="contained" color="secondary" onClick={handleDecommissionClicked}>
              Decommission
            </Button>
          </Box>
        </HasPermission>
      )}

      {cpoChargePoint && (
        <FormDialog
          open={showDecommissionForm}
          onClose={handleCancelAction}
          title={`Decommission ${cpoChargePoint.physicalReference}`}
          fullPage={false}
        >
          <DecommissionChargerForm displayName={cpoChargePoint.physicalReference} cpoChargePointId={cpoChargePoint.id} />
        </FormDialog>
      )}

      <SuccessBar
        open={!_.isEmpty(state.successMessage)}
        fullPage={false}
        onClose={() => {
          addEvent({ type: ChargerInfoEvent.CLOSE_SUCCESS_DIALOG });
        }}
      >
        {state.successMessage}
      </SuccessBar>
      <ProcessingBar open={showProcessing} fullPage={false} />
      <ErrorBar
        open={!_.isEmpty(state.errorMessage)}
        fullPage={false}
        onClose={() => {
          addEvent({ type: ChargerInfoEvent.CLOSE_ERROR_DIALOG });
        }}
      >
        {state.errorMessage}
      </ErrorBar>
    </LoadingContainerWithErrorPanel>
  );
};

export default withChargerInfo(ChargerInfo);
