import _ from 'lodash';
import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, map } from 'rxjs/operators';
import { chargePointStatus } from '../domain/chargePoint';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { buildUrlWithQueryString } from '../utils/url/buildQueryString';
import { toChargePointGroups } from './chargePointGroupService';

export const chargePointMode = {
  AUTH: 'AUTH',
  NON_AUTH: 'FREE'
};

const toTimeToWaitForReset = (waitTime) => ({
  timeToWait: waitTime.time_to_wait
});

const toEvses = (evses) => {
  return evses.map((evse) => ({
    id: evse.id,
    status: evse.status,
    connector: {
      standard: evse.connector.standard,
      format: evse.connector.format,
      powerType: evse.connector.power_type,
      maxVoltage: evse.connector.max_voltage,
      maxAmperage: evse.connector.max_amperage
    }
  }));
};

const toEvsesWithMultipleConnectors = (evses) => {
  return evses.map((evse) => ({
    id: evse.id,
    status: evse.status,
    connector: evse.connectors.map((connector) => ({
      standard: connector.standard,
      format: connector.format,
      powerType: connector.power_type,
      maxVoltage: connector.max_voltage,
      maxAmperage: connector.max_amperage,
      id: connector.ocpp_connector_id,
      status: connector.status
    }))[0]
  }));
};

const toChargePointSummary = (response) => ({
  ocppChargePointId: response.ocpp_charge_point_id,
  evses: toEvsesWithMultipleConnectors(response.evses)
});

const toLocation = (location) => ({
  id: location.id,
  groupName: location.owner.name,
  cpo: location.cpo,
  address: location.address,
  mode: location.mode,
  status: location.decommissioned ? chargePointStatus.DECOMMISSIONED : location.status,
  parkingType: _.titleCase(location.parking_type),
  operationalCost: {
    electricityCost: {
      ratePerKwh: location.operational_cost.electricity_cost.rate_per_kwh,
      currency: location.operational_cost.electricity_cost.currency
    }
  },
  evses: toEvses(location.evses),
  commissioned: location.commissioned,
  decommissionedDate: location.decommissioned_date
});

const toLocations = (locations) => {
  return locations.map((location) => toLocation(location));
};

const toStartTransactionPreview = (response) => ({
  name: response.name,
  isCreditCardPaymentRequired: response.is_credit_card_payment_required,
  cardDetails: {
    brand: response.card_details[0]?.brand,
    lastFourDigits: response.card_details[0]?.last_four_digits
  },
  isPostpay: response.is_postpay,
  postpayAccountName: response.postpay_account_name
});

const locationService = {
  getLocations(chargePointGroupId) {
    const url = buildUrlWithQueryString(`${appConfig.emspAdminApiDomainV2}/locations`, {
      charge_point_group_id: chargePointGroupId
    });
    return withAccessToken((accessToken) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => {
        return toLocations(response.response.data);
      }),
      catchError((error) => errorMessageObservable(error))
    );
  },

  getLocationById: (locationId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${locationId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toLocation(response.response)),
      catchError((error) => errorMessageObservable(error))
    ),

  updateLocation: (currentId, id, mode, group, electricityCost) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${currentId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: {
          id: id,
          mode: mode,
          group: group,
          operational_cost: {
            electricity_cost: {
              rate_per_kwh: electricityCost
            }
          }
        }
      }).pipe(catchError((error) => throwError(new Error(error.response.message))))
    ),

  resetLocation: (locationId, type) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${locationId}/reset-requests`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: {
          type: type
        }
      }).pipe(
        map((response) => toTimeToWaitForReset(response.response)),
        catchError((error) => throwError(new Error(error.response.message)))
      )
    ),

  getChargePointById: (locationId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${locationId}/charge-point`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargePointSummary(response.response)),
      catchError((error) => errorMessageObservable(error))
    ),

  createLocation: (createLocationParameters) => {
    const connectors = createLocationParameters.connectors;
    const evses = connectors.map((connector, index) => {
      return {
        connectors: [
          {
            format: connector.format,
            max_amperage: connector.maxAmperage,
            max_electric_power: connector.maxPower,
            max_voltage: connector.maxVoltage,
            ocpp_connector_id: connector.id,
            power_type: connector.powerType,
            standard: connector.standard
          }
        ]
      };
    });

    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: {
          address: createLocationParameters.address,
          charge_point: {
            anonymous_charging_allowed: createLocationParameters.anonymousChargingMode,
            evses: evses,
            ocpp_charge_point_identifier: createLocationParameters.ocppChargePointIdentifier
          },
          city: createLocationParameters.city,
          coordinates: {
            latitude: createLocationParameters.latitude,
            longitude: createLocationParameters.longitude
          },
          country: createLocationParameters.country,
          name: createLocationParameters.name,
          postal_code: createLocationParameters.postCode,
          state: createLocationParameters.state,
          time_zone: 'Pacific/Auckland'
        }
      }).pipe(
        delay(1000),
        catchError((error) => throwError(new Error(error.response.message)))
      )
    );
  },
  updateConnectors: (locationId, connectors) => {
    const evses = connectors.map((connector, index) => {
      return {
        connectors: [
          {
            format: connector.format,
            max_amperage: connector.maxAmperage,
            max_electric_power: connector.maxPower,
            max_voltage: connector.maxVoltage,
            ocpp_connector_id: connector.id,
            power_type: connector.powerType,
            standard: connector.standard
          }
        ]
      };
    });

    return withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV2}/locations/${locationId}/evses`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: {
          evses: evses
        }
      }).pipe(catchError((error) => throwError(new Error(error.response.message))))
    );
  },
  createStartTransactionPreview: (locationId, email) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/locations/${locationId}/start-transaction-previews`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: {
          email: email
        }
      })
    ).pipe(
      map((response) => toStartTransactionPreview(response.response)),
      catchError((error) => {
        if (error.status === 402) {
          return throwError(new Error('User does not have a stored credit card'));
        }
        return throwError(new Error(error.response.message));
      })
    ),
  createStartTransactionRequest: (locationId, email, evseUid, connectorId) =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomainV3}/locations/${locationId}/start-transaction-requests`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: {
          email: email,
          evse_uid: evseUid
        }
      })
    ).pipe(
      catchError((error) => {
        if (error.status === 402) {
          if (error.response.decline_code === 'insufficient_funds_or_expired') {
            return throwError(new Error('Insufficient funds or expired card. Please ask user to update payment details and retry'));
          }
          return throwError(new Error('Something went wrong payments, please ask user to check their payment method'));
        }
        return throwError(new Error(error.response.message));
      })
    ),
  getAllGroupsForAdmin: () =>
    withAccessToken((accessToken) =>
      ajax({
        url: `${appConfig.emspAdminApiDomain}/charge-point-groups`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toChargePointGroups(response.response.data)),
      catchError((error) => errorMessageObservable(error))
    )
};

export default locationService;
