import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import TransactionInfo from './TransactionInfo';
import {withTransactionDetails} from './hook/hook/withTrasactionDetails';
import useTransactionDetails, {TransactionDetailsEvent, TransactionDetailsFlowState} from './hook/hook/useTransactionDetails';
import LoadingContainerWithErrorPanel from '../../commons/containers/LoadingContainer/LoadingContainerWithErrorPanel';
import {ErrorBar, ProcessingBar, SuccessBar} from '../../commons/snackbars/snackbars';
import _ from 'lodash';
import {WarningDialog} from '../../commons/dialogs/dialogs';

const TransactionInfoPanel = ({transactionId}) => {
  const {state, addEvent} = useTransactionDetails();
  const handleStop = () => {
    addEvent(
      {
        type: TransactionDetailsEvent.STOP_TRANSACTION_REQUESTED,
        payload: {
          id: transactionId
        }
      });
  };
  useEffect(() => {
    if (state.flowState === TransactionDetailsFlowState.INIT || state.flowState === TransactionDetailsFlowState.STOP_TRANSACTION_SUBMITTED) {
      addEvent({type: TransactionDetailsEvent.LOAD_TRANSACTION_REQUESTED, payload: {id: transactionId}});
    }
  }, [state.flowState, addEvent, transactionId,]);
  
  return (
    <>
      <LoadingContainerWithErrorPanel {...state.loadingState}>{state.transaction && <TransactionInfo transaction={state.transaction} onStop={handleStop}/>}</LoadingContainerWithErrorPanel>
      <ProcessingBar open={state.flowState === TransactionDetailsFlowState.SUBMITTING_STOP_TRANSACTION} text={"Initiating transaction stop request..."}/>
      <ErrorBar open={!_.isNull(state.transactionErrorMessage)}>{state.transactionErrorMessage}</ErrorBar>
      <SuccessBar open={state.showStopTransactionRequestedSuccess}>
        Stop transaction has been requested. Updating may take some time, please refresh if transaction is still active.
      </SuccessBar>
      <WarningDialog
        open={_.includes([TransactionDetailsFlowState.SHOWING_CONFIRM_STOP_TRANSACTION_DIALOG], state.flowState)}
        buttonLabel='Stop transaction'
        buttonCallback={() =>
          addEvent({
            type: TransactionDetailsEvent.STOP_TRANSACTION_CONFIRMED,
            payload: {
              id: transactionId
            }
          })}
        onClose={() => addEvent({type: TransactionDetailsEvent.CONFIRM_STOP_TRANSACTION_DIALOG_CLOSED})}
      >
        Are you sure you want to stop Transaction: {transactionId}?
      </WarningDialog>
    </>
  
  )
};

TransactionInfoPanel.propTypes = {
  transactionId: PropTypes.string.isRequired,
};

export default withTransactionDetails(TransactionInfoPanel);
