export interface UserPermission {
  action: string;
  group?: string;
}

export interface UserAdminPermissions {
  isLocationAdmin: boolean;
  isAccountAdmin: boolean;
  isOrgAdmin: boolean;
  isHelpdesk: boolean;
  isSuperAdmin: boolean;
}

export enum Permission {
  VIEW_REPORTS = 'VIEW_REPORTS',
  CREATE_CHARGE_POINT_GROUPS = 'CREATE_CHARGE_POINT_GROUPS',
  VIEW_ALL_USERS = 'VIEW_ALL_USERS',
  UPDATE_CHARGE_POINT_DETAILS = 'UPDATE_CHARGE_POINT_DETAILS',
  MANAGE_USER_GROUP = 'MANAGE_USER_GROUP',
  DELETE_CHARGE_POINT_GROUP = 'DELETE_CHARGE_POINT_GROUP',
  CREATE_CHARGE_POINT = 'CREATE_CHARGE_POINT',
  REMOTE_STOP_TRANSACTION = 'REMOTE_STOP_TRANSACTION',
  REMOTE_START_TRANSACTION = 'REMOTE_START_TRANSACTION',
  MANAGE_ORGANISATION = 'MANAGE_ORGANISATION',
  VIEW_ORGANISATION = 'VIEW_ORGANISATION',
  MANAGE_ACCOUNT = 'MANAGE_ACCOUNT',
  CREATE_ORGANISATION = 'CREATE_ORGANISATION',
  DECOMMISSION_CHARGE_POINT = 'DECOMMISSION_CHARGE_POINT'
}

export interface PortalPermissionState {
  permissions: UserPermission[];
  initialised: boolean;
  errorMessage: string | null;
}
