import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface ConnectorActionButtonProps {
  handleAddConnector: () => void;
  handleRemoveConnector: () => void;
  shouldDisplayRemoveButton: boolean;
}

export const ConnectorActionButtonsContainer: React.FC<ConnectorActionButtonProps> = ({
  handleAddConnector,
  handleRemoveConnector,
  shouldDisplayRemoveButton
}) => {
  return (
    <Box>
      {shouldDisplayRemoveButton && (
        <IconButton size="small" aria-label="Remove connector" color="primary" onClick={() => handleRemoveConnector()}>
          <RemoveCircleIcon fontSize="large" />
        </IconButton>
      )}
      <IconButton size="small" aria-label="Add connector" color="primary" onClick={() => handleAddConnector()}>
        <AddCircleIcon fontSize="large" />
      </IconButton>
    </Box>
  );
};
