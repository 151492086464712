import { Box, IconButton, Typography, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Field, FieldArray, FormikProps } from 'formik';
import React, { RefObject } from 'react';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import SmartSelectField from '../../../commons/form/SmartSelectField';
import SmartTextField from '../../../commons/form/SmartTextField';
import { ConnectorPowerTypeDisplay, ConnectorStandardDisplay, CpoConnector, NewCpoChargePoint } from '../../../types/charger/Charger';
import { ConnectorActionButtonsContainer } from '../ChargerConnectors/ConnectorActionButtonsContainer';
import { CpoConnectors } from '../ChargerDetails/ChargerInfo/ChargerInfo';
import { ReactElementLike } from 'prop-types';
import { isNumeric } from 'rxjs/internal-compatibility';

interface ConnectorsFormProps {
  formikRef: RefObject<FormikProps<NewCpoChargePoint>> | RefObject<FormikProps<CpoConnectors>>;
  bottomActionButtons?: ReactElementLike | null | undefined;
}

const useStyles = makeStyles((theme) => ({
  connectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(2)
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  connectorsBox: {
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: '15px',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
    minWidth: 300
  },
  connectorTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));
const NewConnectors: React.FC<ConnectorsFormProps> = ({ formikRef, bottomActionButtons }) => {
  const classes = useStyles();
  const connectors = formikRef.current?.values.connectors;
  const handleMaxPowerUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const connectorFieldIndex = e.target.name.split('.')[1] as unknown as number;
    const currentConnector = formikRef.current?.values.connectors[connectorFieldIndex];
    const maxAmperage = currentConnector?.maxAmperage;
    const maxVoltage = currentConnector?.maxVoltage;
    if (!maxAmperage || !maxVoltage || !isNumeric(maxAmperage) || !isNumeric(maxVoltage)) return;
    formikRef.current?.setFieldValue(`connectors.${connectorFieldIndex}.maxElectricPower`, maxAmperage * maxVoltage);
  };
  const shouldDisplayRemoveIcon = connectors ? connectors.length > 1 : false;

  const newConnectorValues = {
    format: '',
    maxAmperage: 0,
    maxVoltage: 0,
    maxElectricPower: 0,
    powerType: '',
    standard: '',
    ocppConnectorId: connectors ? connectors.length + 1 : 1
  };

  const connectorStandardOptions = Array.from(ConnectorStandardDisplay, ([key, value]) => ({ value: key, displayValue: value }));
  const connectionPowerTypeOptions = Array.from(ConnectorPowerTypeDisplay, ([key, value]) => ({ value: key, displayValue: value }));

  return (
    <FieldArray name="connectors">
      {({ push, remove, form }) => {
        const connectors = form.values.connectors;
        const handleAddConnector = () => {
          push(newConnectorValues);
        };

        const handleRemoveConnector = () => {
          remove(connectors.length - 1);
        };

        return (
          <PaperWithTitle
            title="Connectors"
            topRightComponent={
              <ConnectorActionButtonsContainer
                handleAddConnector={handleAddConnector}
                handleRemoveConnector={handleRemoveConnector}
                shouldDisplayRemoveButton={shouldDisplayRemoveIcon}
              />
            }
            bottomRightComponent={bottomActionButtons}
          >
            <Box className={classes.connectorsContainer}>
              {connectors.map((connector: CpoConnector, index: number) => {
                const connectorPath = `connectors.${index}`;
                return (
                  <Box key={index} className={classes.connectorsBox}>
                    <Box className={classes.connectorTitle}>
                      <Typography className={classes.connectorTitleHeading} variant="h4">
                        Connector
                      </Typography>
                      {shouldDisplayRemoveIcon && (
                        <IconButton onClick={() => handleRemoveConnector()}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                    <Field
                      label="Id"
                      name={`${connectorPath}.ocppConnectorId`}
                      component={SmartTextField}
                      value={index}
                      helperText="Id of the connector"
                    />
                    <Field
                      label="Format"
                      name={`${connectorPath}.format`}
                      component={SmartSelectField}
                      options={[
                        { value: 'CABLE', displayValue: 'CABLE' },
                        { value: 'SOCKET', displayValue: 'SOCKET' }
                      ]}
                      helperText="Format of the connector"
                    />
                    <Field
                      label="PowerType"
                      name={`${connectorPath}.powerType`}
                      component={SmartSelectField}
                      options={connectionPowerTypeOptions}
                      helperText="Power type of the connector"
                    />
                    <Field
                      label="Type"
                      name={`${connectorPath}.standard`}
                      component={SmartSelectField}
                      options={connectorStandardOptions}
                      helperText="Type of the connector"
                    />
                    <Field
                      label="Max Amperage (A)"
                      name={`${connectorPath}.maxAmperage`}
                      component={SmartTextField}
                      helperText="Max amperage of the connector"
                      onKeyUp={handleMaxPowerUpdate}
                    />
                    <Field
                      label="Max Voltage (V)"
                      name={`${connectorPath}.maxVoltage`}
                      component={SmartTextField}
                      helperText="Max voltage of the connector"
                      onKeyUp={handleMaxPowerUpdate}
                    />
                    <Field
                      label="Max Power (Watts)"
                      name={`${connectorPath}.maxElectricPower`}
                      component={SmartTextField}
                      disabled={true}
                      helperText="Max power of the connector"
                    />
                  </Box>
                );
              })}
            </Box>
          </PaperWithTitle>
        );
      }}
    </FieldArray>
  );
};

export default NewConnectors;
