import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';
import { Field, Form, Formik } from 'formik';

import _ from 'lodash';
import { createRef, FC, useEffect } from 'react';
import * as Yup from 'yup';

import BoxWithLabel from '../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../commons/containers/PaperWithTile/PaperWithTitle';
import SmartCheckboxField from '../../../commons/form/SmartCheckboxField';
import { ErrorBar, ProcessingBar } from '../../../commons/snackbars/snackbars';
import { connectorTypeMap } from '../../../domain/connector';
import ChargePointConnectorStatus from '../../ChargePointDetails/ChargePointConnector/ChargePointConnectorStatus';
import { RemoteStartFlowState } from '../../RemoteStartTransaction/hook/useRemoteStartTransactions';
import useRemoteStartTransactions, { RemoteStartEvent } from './hook/useRemoteStartTransactions';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  submitButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  infoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  infoText: {
    padding: theme.spacing(1)
  }
}));

interface RemoteStartTransactionReviewStepsProps {
  locationId: string;
  displayName?: string;
}

const RemoteStartTransactionReviewSteps: FC<RemoteStartTransactionReviewStepsProps> = ({ locationId, displayName }) => {
  const classes = useStyles();
  const { state, addEvent } = useRemoteStartTransactions();
  const formikRef = createRef<any>();

  const handleBackBtn = () => {
    addEvent({
      type: RemoteStartEvent.REVIEW_REMOTE_START_CANCELLED
    });
  };

  useEffect(() => {
    if (state.flowState === RemoteStartFlowState.FAILED_TO_START) {
      formikRef.current.setSubmitting(false);
    }
  }, [state.flowState, formikRef]);

  const initialValues = {
    confirmation: false
  };
  const validationSchema = Yup.object({
    confirmation: Yup.boolean().oneOf([true], 'Please confirm the details above')
  });
  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        onSubmit={(values) => {
          addEvent({
            type: RemoteStartEvent.START_TRANSACTION_SUBMITTED,
            payload: {
              locationId: displayName,
              confirmation: values.confirmation
            }
          });
        }}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <Form>
            <PaperWithTitle title={'Review Remote Start Transaction'}>
              <>
                <Box>
                  <TextWithLabel label="Charger ID" text={displayName} />
                  <TextWithLabel label="Connector Type" text={connectorTypeMap.get(state.evse?.connectors[0].standard)} />
                  <BoxWithLabel label="Connector Status">
                    <ChargePointConnectorStatus status={state.evse?.status} />
                  </BoxWithLabel>
                  <TextWithLabel label="User" text={state.preview.name} />
                  <TextWithLabel label="Email" text={state.email} />
                  {state.preview.isCreditCardPaymentRequired && (
                    <TextWithLabel label="Credit Card" text={`●●●● ●●●● ●●●● ${state.preview.cardDetails?.lastFourDigits}`} />
                  )}
                  {state.preview.isPostpay && <TextWithLabel label="Postpay Account" text={state.preview.postpayAccountName} />}
                </Box>
                <Field
                  label="I have confirmed the customer's identity and obtained their consent to initiate transactions using their stored payment method"
                  name="confirmation"
                  component={SmartCheckboxField}
                />
                <Box className={classes.infoBox}>
                  <InfoOutlined />
                  <Box className={classes.infoText}>
                    <Typography>Please advise user to plug in their vehicle.</Typography>
                  </Box>
                </Box>
              </>
            </PaperWithTitle>
            <Box className={classes.submitButtonContainer}>
              <Button
                type="button"
                variant="outlined"
                color="primary"
                className={classes.submitButton}
                disabled={formik.isSubmitting}
                onClick={() => handleBackBtn()}
              >
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
              >
                Remote Start Transaction
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
      <ErrorBar open={!_.isNull(state.errorMessage)} fullPage={undefined} onClose={undefined}>
        {state.errorMessage}
      </ErrorBar>
      <ProcessingBar
        open={state.flowState === RemoteStartFlowState.TRANSACTION_STARTING}
        text={'Sending Remote Start Command..'}
        fullPage={undefined}
      />
    </>
  );
};

export default RemoteStartTransactionReviewSteps;
