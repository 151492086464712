import MaterialTableWrapper from '../../../commons/table/MaterialTableWrapper';
import dateTimeFormatter from '../../../utils/date/dateTimeFormatter';
import * as PropTypes from 'prop-types';
import TransactionStatus from '../TransactionStatus/TransactionStatus';
import { roundNumberToCurrency } from '../../../utils/transaction/transactionUtils';
import React from 'react';
import _ from 'lodash';
import TransactionCarbonOffsetWithTooltip from '../TransactionCarbonOffsetWithTooltip/TransactionCarbonOffsetWithTooltip';
import NavigationLink from '../../../commons/link/NavigationLink';
import paths from '../../../routes/paths';
import { FeatureFlag, useFeatureFlag } from '../../../hooks/useFeatureFlags';

const TransactionsTable = ({ transactions, dateRangeFilter, hideUserInfo, hideChargePointInfo, hideOperationalExpenditure }) => {
  const hideNewLocationModelColumn = !useFeatureFlag(FeatureFlag.ORGANISATION_DISPLAY);
  return (
    <>
      <MaterialTableWrapper
        columns={[
          {
            title: 'Date',
            field: 'created',
            width: 'auto',
            cellStyle: { width: 120 },
            render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.created),
            customFilterAndSearch: (filter, rowData) =>
              _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.created), filter),
            defaultSort: 'desc',
            export: false
          },
          {
            title: 'Created',
            field: 'readableCreated',
            type: 'datetime',
            hidden: true,
            export: true
          },
          {
            title: 'Transaction Id',
            field: 'transactionId',
            cellStyle: { width: 100 },
            render: (rowData) => <NavigationLink path={`${paths.TRANSACTIONS}/${rowData.transactionId}`} text={rowData.transactionId} />
          },
          {
            title: 'User Id',
            field: 'userId',
            hidden: true,
            cellStyle: { width: 120 },
            width: 'auto',
            hiddenByColumnsButton: true
          },
          {
            title: 'User Name',
            field: 'userName',
            cellStyle: { width: 200 },
            hidden: hideUserInfo
          },
          {
            title: 'Charge Tag Id',
            field: 'chargeTagId',
            cellStyle: { width: 200 }
          },
          {
            title: 'Account',
            field: 'accountName',
            cellStyle: { width: 200 },
            hidden: hideUserInfo || hideNewLocationModelColumn
          },
          {
            title: 'Revenue',
            field: 'revenue',
            type: 'currency',
            cellStyle: { width: 120 },
            align: 'right'
          },
          {
            title: 'Prepaid Amount Charged',
            field: 'prepaidAmount',
            type: 'currency',
            cellStyle: { width: 120 },
            width: 'auto',
            align: 'right',
            export: true
          },
          {
            title: 'Amount Paid by Credit Card',
            field: 'creditCardPayment',
            type: 'currency',
            cellStyle: { width: 120 },
            width: 'auto',
            align: 'right',
            export: true
          },
          {
            title: 'Postpay Amount Charged',
            field: 'postpayAmount',
            type: 'currency',
            cellStyle: { width: 120 },
            width: 'auto',
            align: 'right',
            export: true,
            hidden: hideNewLocationModelColumn
          },
          {
            title: 'Cost',
            field: 'operationalExpenditure',
            type: 'currency',
            cellStyle: { width: 80 },
            hidden: hideOperationalExpenditure
          },
          {
            title: 'Currency',
            field: 'currency',
            align: 'right',
            cellStyle: { width: 80 }
          },
          {
            title: 'Charge Point Fleet',
            field: 'chargePointFleet',
            cellStyle: { width: 160 },
            hidden: hideChargePointInfo
          },
          {
            title: 'Organisation',
            field: 'organisation',
            cellStyle: { width: 160 },
            hidden: hideChargePointInfo || hideNewLocationModelColumn
          },
          {
            title: 'Charge Point Id',
            field: 'locationId',
            cellStyle: { width: 160 },
            hidden: hideChargePointInfo
          },
          {
            title: 'Location',
            field: 'locationName',
            cellStyle: { width: 250 },
            hidden: hideChargePointInfo || hideNewLocationModelColumn
          },
          {
            title: 'Charge Point Address',
            field: 'locationAddress',
            cellStyle: { width: 250 },
            hidden: hideChargePointInfo
          },
          {
            title: 'Duration (seconds)',
            field: 'durationInSeconds',
            type: 'numeric',
            cellStyle: { width: 80 }
          },
          {
            title: 'Energy (kWh)',
            field: 'kwh',
            type: 'numeric',
            cellStyle: { width: 80 }
          },
          {
            title: 'Carbon Offset (kg CO2)',
            field: 'carbonOffset',
            render: (rowData) => (
              <TransactionCarbonOffsetWithTooltip
                carbonOffset={rowData.carbonOffset}
                electricityEmissions={rowData.electricityEmissions}
                petrolEmissions={rowData.petrolEmissions}
              />
            ),
            cellStyle: { width: 120 }
          },
          {
            title: 'EV State of Charge (SoC) Start',
            field: 'startStateOfCharge',
            type: 'numeric',
            render: (rowData) =>
              rowData.startStateOfCharge ? <div style={{ paddingLeft: 100 }}>{_.round(rowData.startStateOfCharge, 1)}%</div> : <>N/A</>,
            cellStyle: { width: 200 }
          },
          {
            title: 'EV State of Charge (SoC) End',
            field: 'endStateOfCharge',
            type: 'numeric',
            render: (rowData) =>
              rowData.endStateOfCharge ? <div style={{ paddingLeft: 100 }}>{_.round(rowData.endStateOfCharge, 1)}%</div> : <>N/A</>,
            cellStyle: { width: 200 }
          },
          {
            title: 'Status',
            field: 'status',
            render: (rowData) => <TransactionStatus status={rowData.status} />,
            cellStyle: { width: 120 }
          }
        ]}
        data={transactions.map((transaction) => ({
          created: transaction.session.startDateTime,
          readableCreated: dateTimeFormatter.printReadableDateTime(transaction.session.startDateTime),
          transactionId: transaction.id,
          userId: transaction.user.id,
          userName: transaction.user.name,
          accountName: transaction.user.accountName,
          chargeTagId: transaction.user.chargeTagId,
          chargePointFleet: transaction.location.owner,
          organisation: transaction.location.owner,
          locationId: transaction.location.id,
          locationName: transaction.location.name,
          locationAddress: transaction.location.address,
          durationInSeconds: transaction.session.durationInSeconds,
          kwh: transaction.session.kwh.toFixed(2),
          revenue: roundNumberToCurrency(transaction.payment.total),
          prepaidAmount: roundNumberToCurrency(transaction.paymentBreakdown.prepaidAmount),
          creditCardPayment: roundNumberToCurrency(transaction.paymentBreakdown.creditCardPayment),
          postpayAmount: roundNumberToCurrency(transaction.paymentBreakdown.postpayAmount),
          currency: 'NZD',
          status: transaction.status,
          carbonOffset: transaction.carbonEmissions.carbonOffset,
          electricityEmissions: transaction.carbonEmissions.electricityEmissions.amount,
          petrolEmissions: transaction.carbonEmissions.petrolEmissions.amount,
          operationalExpenditure: transaction.operationalExpenditure.electricityCostTotal,
          startStateOfCharge: transaction.session.startStateOfCharge,
          endStateOfCharge: transaction.session.latestStateOfCharge
        }))}
        exportFileName="Transactions"
        dateRangeFilter={dateRangeFilter}
      />
    </>
  );
};

TransactionsTable.defaultProps = {
  hideUserInfo: false,
  hideChargePointInfo: false,
  hideOperationalExpenditure: true
};

TransactionsTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  dateRangeFilter: PropTypes.object,
  hideUserInfo: PropTypes.bool,
  hideChargePointInfo: PropTypes.bool,
  hideOperationalExpenditure: PropTypes.bool
};

export default TransactionsTable;
