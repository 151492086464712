import React from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import mainTheme from '../../../themes/mainTheme';

const useStyles = makeStyles((theme) => ({
  connectorStatus: {
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: 500,
  },
  available: {
    backgroundColor: theme.palette.connectorStatus.available,
  },
  unavailable: {
    backgroundColor: theme.palette.connectorStatus.unavailable,
  },
  charging: {
    backgroundColor: theme.palette.connectorStatus.charging,
  },
}));

const ChargePointConnectorStatus = ({status}) => {
  const classes = useStyles(mainTheme);
  return (
    <Box
      width={150}
      className={clsx(classes.connectorStatus, {
        [classes.available]: status === 'AVAILABLE',
        [classes.charging]: status === 'CHARGING',
        [classes.unavailable]: status !== 'AVAILABLE' && status !== 'CHARGING',
      })}
    >
      {status}
    </Box>
  );
};

ChargePointConnectorStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

ChargePointConnectorStatus.defaultProps = {
  status: 'LOADING...',
};

export default ChargePointConnectorStatus;
