import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { FC, useEffect } from 'react';
import { MdChevronRight } from 'react-icons/md';
import BoxWithLabel from '../../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../../commons/containers/ComponentWithLabel/TextWithLabel';

import { connectorTypeMap } from '../../../../domain/connector';
import ChargePointConnectorIcon from '../../../ChargePointDetails/ChargePointConnector/ChargePointConnectorIcon';
import ChargePointConnectorStatus from '../../../ChargePointDetails/ChargePointConnector/ChargePointConnectorStatus';
import { RemoteStartFlowState } from '../../../RemoteStartTransaction/hook/useRemoteStartTransactions';
import useRemoteStartTransactions, { RemoteStartEvent } from '../hook/useRemoteStartTransactions';

const useStyles = makeStyles((theme) => ({
  connectorBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    paddingTop: theme.spacing(1)
  },
  connectorButton: {
    margin: theme.spacing(1)
  }
}));

interface RemoteStartChooseConnectorFormProps {
  locationId: string;
  displayName?: string;
}

const RemoteStartChooseConnectorForm: FC<RemoteStartChooseConnectorFormProps> = ({ locationId, displayName }) => {
  const classes = useStyles();
  const { state, addEvent } = useRemoteStartTransactions();

  const handleChooseConnector = (evse: any) => {
    addEvent({
      type: RemoteStartEvent.CONNECTOR_SUBMITTED,
      payload: {
        evse: evse
      }
    });
  };

  useEffect(() => {
    if (state.flowState === RemoteStartFlowState.ELIGIBLE) {
      addEvent({
        type: RemoteStartEvent.CONNECTORS_OPENED,
        payload: {
          locationId: locationId,
          displayName: displayName
        }
      });
    }
  }, [locationId, displayName, addEvent, state.flowState]);

  return (
    <>
      <Typography variant="h3">Choose connector</Typography>
      {state.connectors.length > 0 &&
        state.connectors.map((evse: any) => {
          return (
            <Button className={classes.connectorButton} key={evse.id} onClick={() => handleChooseConnector(evse)} fullWidth>
              <Box className={classes.connectorBox}>
                <ChargePointConnectorIcon type={evse.connectors[0].standard} />
                <TextWithLabel label="Type" text={connectorTypeMap.get(evse.connectors[0].standard)} />
                <BoxWithLabel label="Status">
                  <ChargePointConnectorStatus status={evse.status} />
                </BoxWithLabel>
                {evse.connectors[0].id && <TextWithLabel label="OCPP ID" text={evse.connectors[0].cpoConnectorId} />}
                <MdChevronRight size={30} />
              </Box>
            </Button>
          );
        })}
    </>
  );
};

export default RemoteStartChooseConnectorForm;
