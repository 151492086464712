import React, { ComponentType, createContext, useContext, useEffect, useReducer } from 'react';
import { featureFlagService } from '../services/featureFlagService';
import useAuthentication from './useAuthentication';
import GenericNotFound from '../components/GenericNotFound/GenericNotFound';
import { Redirect } from 'react-router';

export enum FeatureFlag {
  LOCATION_DISPLAY = 'location_display',
  ORGANISATION_DISPLAY = 'organisation_display',
  CHARGERS_DISPLAY = 'charger_display',
  DASHBOARD_UNDER_MAINTENANCE = 'dashboard_under_maintenance',
  LEGACY_CHARGE_POINT_MANAGEMENT = 'legacy_charge_point_management',
  CPO_TARIFF_ADMINISTRATION = 'cpo_tariff_administration'
}

// Default values for any feature flags before fetching
const initialState = {
  [FeatureFlag.LOCATION_DISPLAY]: false,
  [FeatureFlag.ORGANISATION_DISPLAY]: false,
  [FeatureFlag.CHARGERS_DISPLAY]: false,
  [FeatureFlag.DASHBOARD_UNDER_MAINTENANCE]: false,
  [FeatureFlag.LEGACY_CHARGE_POINT_MANAGEMENT]: true,
  [FeatureFlag.CPO_TARIFF_ADMINISTRATION]: false
};

enum ActionType {
  LOAD_FEATURE_FLAG = 'loadFeatureFlag'
}

interface FeatureFlagAction {
  type: ActionType;
  featureFlag: FeatureFlag;
  enabled: boolean;
}

const reducer = (state: typeof initialState, action: FeatureFlagAction) => {
  switch (action.type) {
    case ActionType.LOAD_FEATURE_FLAG:
      return {
        ...state,
        [action.featureFlag]: action.enabled
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const featureFlagContext = createContext(initialState);

export const FeatureFlagProvider: React.FC<React.PropsWithChildren<any>> = ({ children }) => {
  const { user } = useAuthentication();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (!user) return;

    // Refresh feature flags whenever the user changes and is logged in
    Object.values(FeatureFlag).forEach((featureFlag) => {
      featureFlagService.getFeatureFlag(featureFlag).subscribe(
        (result) => {
          dispatch({
            type: ActionType.LOAD_FEATURE_FLAG,
            featureFlag: featureFlag,
            enabled: result.enabled
          });
        },
        (error) => console.log(`Unable to load feature flag ${featureFlag}!`)
      );
    });
  }, [user]);

  return <featureFlagContext.Provider value={state}>{children}</featureFlagContext.Provider>;
};

export const useFeatureFlag = (featureFlag: FeatureFlag): boolean => {
  const state = useContext(featureFlagContext);
  return state[featureFlag];
};

export const requiresFeatureFlag =
  (featureFlag: FeatureFlag, options?: { redirectTo?: string }) =>
  <T extends Record<string, unknown>>(Component: ComponentType<T>): React.FC<T> =>
  (props: T) => {
    const alternativeComponent = options?.redirectTo ? <Redirect to={options.redirectTo} /> : <GenericNotFound />;

    const hasFeatureFlag = useFeatureFlag(featureFlag);
    return hasFeatureFlag ? <Component {...props} /> : alternativeComponent;
  };
