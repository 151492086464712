import { Divider, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import Spinner from 'react-spinkit';
import PaperWithTitle from '../../commons/containers/PaperWithTile/PaperWithTitle';
import HasPermission from '../../commons/permissions/HasPermission';
import { tableIcons } from '../../commons/table/tableIcons';
import mainTheme from '../../themes/mainTheme';
import { Permission } from '../../types/user/UserPermission';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';

const TransactionInfo = ({ transaction, onStop }) => {
  const useStyles = makeStyles((theme) => ({
    bold: {
      fontWeight: 600
    },
    row: {
      gap: theme.spacing(1)
    },
    divider: {
      margin: `${theme.spacing(2)}px 0`
    },
    alert: {
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.sessionStatus.active,
      color: theme.palette.primary.contrastText,
      paddingLeft: theme.spacing(3)
    },
    activeIcon: {
      marginTop: 12,
      color: theme.palette.primary.contrastText,
      '& div': {
        width: theme.spacing(0.25),
        height: theme.spacing(0.25)
      },
      '& div:first-child': {
        display: 'none'
      }
    }
  }));

  const classes = useStyles(mainTheme);

  const buildCostBreakdown = () => {
    const fee = [];
    if (transaction.tariff.ratePerKwh) {
      fee.push({
        rate: `$${transaction.tariff.ratePerKwh.toFixed(2)}/kWh`,
        usage: transaction.session.kwh,
        cost: transaction.payment.kwhFeeTotal
      });
    }

    if (transaction.tariff.ratePerMinute) {
      fee.push({
        rate: `$${transaction.tariff.ratePerMinute.toFixed(2)}/min`,
        usage: dateTimeFormatter.printReadableTimeFromSeconds(transaction.session.durationInSeconds),
        cost: transaction.payment.minuteFeeTotal
      });
    }

    if (transaction.tariff.unlockFee) {
      fee.push({
        rate: `$${transaction.tariff.unlockFee.toFixed(2)} unlock`,
        usage: '',
        cost: transaction.payment.unlockFeeTotal
      });
    }
    return fee;
  };

  const isFree = () => !transaction.tariff.ratePerKwh && !transaction.tariff.ratePerMinute && !transaction.tariff.unlockFee;

  const buildTotalCost = () => (isFree() ? 'FREE' : `$${transaction.payment.total.toFixed(2)}`);

  const shouldShowPaymentBreakdown =
    transaction.status === 'COMPLETED' &&
    (transaction.paymentBreakdown.prepaidAmount !== 0 ||
      transaction.paymentBreakdown.creditCardPayment !== 0 ||
      transaction.paymentBreakdown.postpayAmount !== 0);
  const stopButton =
    transaction.status === 'ACTIVE' ? (
      <HasPermission permission={Permission.REMOTE_STOP_TRANSACTION}>
        <Button variant="contained" color="secondary" onClick={onStop}>
          Stop
        </Button>
      </HasPermission>
    ) : (
      <></>
    );

  return (
    <PaperWithTitle title={`Id: ${transaction.id}`} topRightComponent={stopButton}>
      <>
        {transaction.status === 'ACTIVE' && (
          <>
            <Alert
              className={classes.alert}
              severity="info"
              icon={<Spinner className={classes.activeIcon} name="ball-clip-rotate-multiple" fadeIn="none" />}
            >
              Active transaction! Usage and costs are not final.
            </Alert>
          </>
        )}

        <Typography className={classes.bold}>{transaction.location.address}</Typography>
        <Typography className={classes.bold}>{transaction.location.name}</Typography>
        <Typography className={classes.bold}>{transaction.location.owner}</Typography>
        <Typography>Station Id: {transaction.location.id}</Typography>
        <Divider className={classes.divider} />

        <Typography className={classes.bold}>Started charging at:</Typography>
        <Typography>{dateTimeFormatter.printReadableDateTime(transaction.session.startDateTime)}</Typography>
        <Divider className={classes.divider} />

        <Typography>
          <b>Starting state of charge:</b> {transaction.session.startStateOfCharge}%
        </Typography>
        <Typography>
          <b>Ending state of charge:</b> {transaction.session.latestStateOfCharge}%
        </Typography>
        <Divider className={classes.divider} />

        {!isFree() && (
          <>
            <MaterialTable
              icons={tableIcons}
              components={{
                Container: (props) => props.children,
                Toolbar: () => <></>
              }}
              style={{
                padding: '0 16px'
              }}
              columns={[
                { title: 'Rate', field: 'rate', align: 'left' },
                { title: 'Usage', field: 'usage', align: 'left' },
                { title: 'Cost', field: 'cost', type: 'currency', align: 'left' }
              ]}
              data={buildCostBreakdown()}
              options={{
                headerStyle: {
                  color: 'black',
                  fontWeight: 600,
                  padding: `0`,
                  borderBottom: '0'
                },
                cellStyle: {
                  padding: `0`,
                  borderBottom: '0'
                },
                showTitle: false,
                search: false,
                paging: false,
                sorting: false
              }}
            />
            <Divider className={classes.divider} />
          </>
        )}

        <Typography className={classes.bold} align="left">
          Total Cost: {buildTotalCost()}
        </Typography>

        {shouldShowPaymentBreakdown && (
          <>
            <Divider className={classes.divider} />
            {transaction.user.accountName && <Typography align="left">Account Name: {transaction.user.accountName}</Typography>}
            {transaction.paymentBreakdown.prepaidAmount > 0 && (
              <Typography className={classes.bold} align="left">
                Prepaid Amount Charged: ${transaction.paymentBreakdown.prepaidAmount.toFixed(2)}
              </Typography>
            )}
            {transaction.paymentBreakdown.creditCardPayment > 0 && (
              <Typography className={classes.bold} align="left">
                Amount Paid By Credit Card: ${transaction.paymentBreakdown.creditCardPayment.toFixed(2)}
              </Typography>
            )}
            {transaction.paymentBreakdown.postpayAmount > 0 && (
              <Typography className={classes.bold} align="left">
                Postpay Amount Charged: ${transaction.paymentBreakdown.postpayAmount.toFixed(2)}
              </Typography>
            )}
          </>
        )}
      </>
    </PaperWithTitle>
  );
};
TransactionInfo.propTypes = {
  transaction: PropTypes.object.isRequired
};

export default TransactionInfo;
