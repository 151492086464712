import React from 'react';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BoxWithLabel from '../../../../commons/containers/ComponentWithLabel/BoxWithLabel';
import TextWithLabel from '../../../../commons/containers/ComponentWithLabel/TextWithLabel';
import PaperWithTitle from '../../../../commons/containers/PaperWithTile/PaperWithTitle';
import EditButton from '../../../../commons/form/button/EditButton';
import PageWithColumns from '../../../../commons/layout/PageWithColumns';
import { ConnectorPowerTypeDisplay, ConnectorStandardDisplay, CpoConnector, EmspConnector } from '../../../../types/charger/Charger';
import ChargePointConnectorStatus from '../../../ChargePointDetails/ChargePointConnector/ChargePointConnectorStatus';
import ChargerConnectorIcon from '../../ChargerConnectors/ChargerConnectorIcon';

type CpoOrEmspConnector = CpoConnector | EmspConnector;

interface ChargerConnectorsPanelProps {
  connectors: CpoOrEmspConnector[];
  canEditConnectors?: boolean;
  handleEditConnectors?: () => void;
}

const useStyles = makeStyles((theme) => ({
  connectorFieldsContainer: {
    borderBottom: `1px dashed ${theme.palette.divider}`,
    display: 'flex',
    flexDirection: 'column',
    minWidth: '200px',
    justifyContent: 'flex-start',
    paddingBottom: theme.spacing(2),
    '&:last-of-type': {
      borderBottom: 'none',
      paddingBottom: 0
    }
  },
  connectorTitleHeading: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  columnTwo: {
    paddingTop: theme.spacing(2)
  }
}));

export const ChargerConnectorsPanel: React.FC<ChargerConnectorsPanelProps> = ({ connectors, canEditConnectors, handleEditConnectors }) => {
  const classes = useStyles();
  const isEmspConnector = (connector: CpoOrEmspConnector): connector is EmspConnector => {
    return (connector as EmspConnector).cpoConnectorId !== undefined;
  };

  const sortEmspConnectors = (connectors: EmspConnector[]): EmspConnector[] => {
    return connectors.sort((a, b) => (a.cpoConnectorId as unknown as number) - (b.cpoConnectorId as unknown as number));
  };

  const sortCpoConnectors = (connectors: CpoConnector[]): CpoConnector[] => {
    return connectors.sort((a, b) => a.ocppConnectorId - b.ocppConnectorId);
  };

  const allConnectors = connectors.flatMap((connector) => connector);
  const sortedConnectors = isEmspConnector(connectors[0])
    ? sortEmspConnectors(allConnectors as EmspConnector[])
    : sortCpoConnectors(allConnectors as CpoConnector[]);
  const connectorActions = <>{canEditConnectors ? <EditButton disabled={false} handleEdit={handleEditConnectors} /> : null}</>;

  return (
    <PaperWithTitle title="Connectors" topRightComponent={connectorActions}>
      <>
        {sortedConnectors?.map((connector, index) => {
          const connectorId = isEmspConnector(connector) ? (connector as EmspConnector).cpoConnectorId : (connector as CpoConnector).ocppConnectorId;
          return (
            <Box key={index} className={classes.connectorFieldsContainer}>
              <Box>
                <PageWithColumns
                  key={index}
                  columnOne={
                    <Box>
                      <ChargerConnectorIcon type={connector.standard} />
                      <TextWithLabel label="OCPP ID" text={connectorId} />
                      <TextWithLabel label="Type" text={ConnectorStandardDisplay.get(connector.standard)} />
                      {connectors && connector.status !== undefined && (
                        <BoxWithLabel label="Status">
                          <ChargePointConnectorStatus status={connector.status} />
                        </BoxWithLabel>
                      )}
                    </Box>
                  }
                  columnTwo={
                    <Box className={classes.columnTwo}>
                      <TextWithLabel label="Format" text={connector.format} />
                      <TextWithLabel label="Power Type" text={ConnectorPowerTypeDisplay.get(connector.powerType)} />
                      <TextWithLabel label="Max Amperage (A)" text={connector.maxAmperage} />
                      <TextWithLabel label="Max Voltage (V)" text={connector.maxVoltage} />
                      <TextWithLabel label="Max Power (Watts)" text={connector.maxElectricPower} />
                    </Box>
                  }
                />
              </Box>
            </Box>
          );
        })}
      </>
    </PaperWithTitle>
  );
};
