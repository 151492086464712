import { EmspEvse } from './Charger';

export enum ChargerRemoteStartFlowState {
  INIT = 'INIT',
  CHECKING_ELIGIBILITY = 'CHECKING_ELIGIBILITY',
  NOT_ELIGIBLE = 'NOT_ELIGIBLE',
  ELIGIBLE = 'ELIGIBLE',
  CONNECTORS_LOADED = 'CONNECTORS_LOADED',
  REVIEW = 'REVIEW',
  FAILED_TO_START = 'FAILED_TO_START',
  TRANSACTION_STARTED = 'TRANSACTION_STARTED',
  TRANSACTION_STARTING = 'TRANSACTION_STARTING'
}

export type ChargerRemoteStartState = {
  flowState: ChargerRemoteStartFlowState;
  errorMessage: string | null;
  isEligible: boolean;
  email: string | null;
  transaction: null;
  preview: {
    name: string | null;
    isCreditCardPaymentRequired: boolean;
    cardDetails: null | { brand: string | null; lastFourDigits: string | null };
    isPostpay: boolean;
    postpayAccountName: string | null;
  };
  evse: EmspEvse | null;
  connectors: [];
};
