import MaterialTableWrapper from '../../commons/table/MaterialTableWrapper';
import React from 'react';
import _ from 'lodash';
import * as PropTypes from 'prop-types';
import dateTimeFormatter from '../../utils/date/dateTimeFormatter';
import { roundNumberToCurrency, toOperationalCostBreakdown, toPaymentBreakdown, toTariffBreakdown } from '../../utils/transaction/transactionUtils';
import TransactionCarbonOffsetWithTooltip from '../Transaction/TransactionCarbonOffsetWithTooltip/TransactionCarbonOffsetWithTooltip';
import TransactionStatus from '../Transaction/TransactionStatus/TransactionStatus';
import { FeatureFlag, useFeatureFlag } from '../../hooks/useFeatureFlags';

const TransactionsReportTable = ({ transactions, dateRangeFilter }) => {
  const hideNewLocationModelColumn = !useFeatureFlag(FeatureFlag.ORGANISATION_DISPLAY);
  return (
    <MaterialTableWrapper
      columns={[
        {
          title: 'Date',
          field: 'created',
          width: 'auto',
          cellStyle: { width: 120 },
          render: (rowData) => dateTimeFormatter.printReadableDateTime(rowData.created),
          customFilterAndSearch: (filter, rowData) => _.includesSubstringIgnoreCase(dateTimeFormatter.printReadableDateTime(rowData.created), filter),
          export: false
        },
        {
          title: 'Created',
          field: 'readableCreated',
          type: 'datetime',
          hidden: true,
          export: true
        },
        {
          title: 'OpenLoop Transaction Id',
          field: 'id',
          cellStyle: { width: 120 }
        },
        {
          title: 'Revenue',
          field: 'revenue',
          type: 'currency',
          cellStyle: { width: 120 },
          align: 'right'
        },
        {
          title: 'Prepaid Amount Charged',
          field: 'prepaidAmount',
          type: 'currency',
          cellStyle: { width: 120 },
          width: 'auto',
          align: 'right',
          export: true
        },
        {
          title: 'Amount Paid by Credit Card',
          field: 'creditCardPayment',
          type: 'currency',
          cellStyle: { width: 120 },
          width: 'auto',
          align: 'right',
          export: true
        },
        {
          title: 'Postpay Amount Charged',
          field: 'postpayAmount',
          type: 'currency',
          cellStyle: { width: 120 },
          width: 'auto',
          align: 'right',
          export: true,
          hidden: hideNewLocationModelColumn
        },
        {
          title: 'Cost',
          field: 'cost',
          type: 'currency',
          cellStyle: { width: 120 },
          align: 'right'
        },
        {
          title: 'Currency',
          field: 'currency',
          align: 'right',
          cellStyle: { width: 60 }
        },
        {
          title: 'Payment Gateway Customer Id',
          field: 'customerId',
          cellStyle: { width: 80 }
        },
        {
          title: 'OpenLoop User Id',
          field: 'userId',
          cellStyle: { width: 80 }
        },
        {
          title: 'Account',
          field: 'accountName',
          cellStyle: { width: 80 },
          hidden: hideNewLocationModelColumn
        },
        {
          title: 'Charge Point Fleet',
          field: 'owner',
          width: 'auto',
          cellStyle: { width: 100 }
        },
        {
          title: 'Organisation',
          field: 'organisation',
          width: 'auto',
          cellStyle: { width: 100 },
          hidden: hideNewLocationModelColumn
        },
        {
          title: 'Location',
          field: 'locationName',
          width: 'auto',
          cellStyle: { width: 100 },
          hidden: hideNewLocationModelColumn
        },
        {
          title: 'Charging Point Id',
          field: 'locationId',
          cellStyle: { width: 160 }
        },
        {
          title: 'Duration (seconds)',
          field: 'durationInSeconds',
          type: 'numeric',
          cellStyle: { width: 80 }
        },
        {
          title: 'Energy (kWh)',
          field: 'kwh',
          type: 'numeric',
          cellStyle: { width: 80 }
        },
        {
          title: 'Operational Cost Breakdown',
          field: 'operationalCostBreakdown',
          cellStyle: { width: 160 }
        },
        {
          title: 'Tariff Breakdown',
          field: 'tariffBreakdown',
          cellStyle: { width: 160 }
        },
        {
          title: 'Payment Breakdown',
          field: 'paymentBreakdown',
          cellStyle: { width: 160 }
        },
        {
          title: 'Carbon Offset (kg CO2)',
          field: 'carbonOffset',
          render: (rowData) => (
            <TransactionCarbonOffsetWithTooltip
              carbonOffset={rowData.carbonOffset}
              electricityEmissions={rowData.electricityEmissions}
              petrolEmissions={rowData.petrolEmissions}
            />
          ),
          cellStyle: { width: 120 }
        },
        {
          title: 'Status',
          field: 'status',
          render: (rowData) => <TransactionStatus status={rowData.status} />,
          cellStyle: { width: 120 }
        }
      ]}
      data={transactions.map((transaction) => ({
        created: transaction.created,
        readableCreated: dateTimeFormatter.printReadableDateTime(transaction.created),
        id: transaction.id,
        revenue: roundNumberToCurrency(transaction.payment.total),
        prepaidAmount: roundNumberToCurrency(transaction.paymentBreakdown.prepaidAmount),
        creditCardPayment: roundNumberToCurrency(transaction.paymentBreakdown.creditCardPayment),
        postpayAmount: roundNumberToCurrency(transaction.paymentBreakdown.postpayAmount),
        cost: roundNumberToCurrency(transaction.operationalExpenditure.electricityCostTotal),
        currency: 'NZD',
        customerId: transaction.user.customerId,
        userId: transaction.user.id,
        accountName: transaction.user.accountName,
        owner: transaction.location.owner,
        organisation: transaction.location.owner,
        locationId: transaction.location.id,
        locationName: transaction.location.name,
        durationInSeconds: transaction.session.durationInSeconds,
        kwh: transaction.session.kwh,
        tariffBreakdown: toTariffBreakdown(transaction.tariff),
        paymentBreakdown: toPaymentBreakdown(transaction.payment),
        operationalCostBreakdown: toOperationalCostBreakdown(transaction.operationalCost),
        carbonOffset: transaction.carbonEmissions.carbonOffset,
        electricityEmissions: transaction.carbonEmissions.electricityEmissions.amount,
        petrolEmissions: transaction.carbonEmissions.petrolEmissions.amount,
        status: transaction.status
      }))}
      exportFileName="Transactions"
      dateRangeFilter={dateRangeFilter}
    />
  );
};

TransactionsReportTable.propTypes = {
  transactions: PropTypes.array.isRequired,
  dateRangeFilter: PropTypes.object.isRequired
};

export default TransactionsReportTable;
