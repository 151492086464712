import { AddEventType, InitialStateType } from '../SharedStates';
import { EmspLocation } from '../location/Location';
import { CpoChargePoint, EmspCharger, NewCpoChargePoint } from './Charger';

export type UseChargerInfoType = {
  state: ChargerInfoState;
  addEvent: AddEventType;
};

export enum ChargerInfoFlowState {
  INIT = 'INIT',
  VIEWING_CHARGER = 'VIEWING_CHARGER',
  SHOW_EDIT_CHARGER = 'SHOW_EDIT_CHARGER',
  CHARGER_UPDATED = 'CHARGER_UPDATED',
  FAILED_TO_UPDATE_CHARGER = 'FAILED_TO_UPDATE_CHARGER',
  UPDATING_CHARGER = 'UPDATING_CHARGER',
  SHOW_RESET_CHARGER = 'SHOW_RESET_CHARGER',
  FAILED_TO_RESET_CHARGER = 'FAILED_TO_RESET_CHARGER',
  SHOW_EDIT_CONNECTORS = 'SHOW_EDIT_CONNECTORS',
  CONNECTORS_UPDATED = 'CONNECTORS_UPDATED',
  UPDATING_CONNECTORS = 'UPDATING_CONNECTORS',
  FAILED_TO_UPDATE_CONNECTORS = 'FAILED_TO_UPDATE_CONNECTORS',
  SHOW_DECOMMISSION_CHARGER_FORM = 'SHOW_DECOMMISSION_CHARGER_FORM'
}

export type ChargerInfoState = {
  successMessage: string;
  flowState: ChargerInfoFlowState;
  loadingState: InitialStateType;
  emspCharger?: EmspCharger;
  cpoChargePoint?: CpoChargePoint;
  errorMessage: string;
};

export enum NewChargerFlowState {
  INIT = 'INIT',
  ENTERING_NEW_CHARGE_POINT_DETAILS = 'ENTERING_NEW_CHARGE_POINT_DETAILS',
  REVIEWING_NEW_CHARGE_POINT_FORM = 'REVIEWING_NEW_CHARGE_POINT_FORM',
  SUBMITTING_CHARGE_POINT_FORM = 'SUBMITTING_CHARGE_POINT_FORM',
  NEW_CHARGE_POINT_CREATED = 'NEW_CHARGE_POINT_CREATED'
}

export type NewChargerState = {
  loadingState: InitialStateType;
  flowState: NewChargerFlowState;
  newChargePoint: NewCpoChargePoint;
  createChargePointErrorMessage: string;
  locations: EmspLocation[];
  newChargePointLocationName: string;
};

export type UseNewChargerType = {
  state: NewChargerState;
  addEvent: AddEventType;
};
