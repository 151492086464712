import React, { createRef, FC, useEffect } from 'react';
import { CpoEvse } from '../../../../types/charger/Charger';
import { CpoConnectors } from './ChargerInfo';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import CancelButton from '../../../../commons/form/button/CancelButton';
import UpdateButton from '../../../../commons/form/button/UpdateButton';
import NewConnectors from '../../NewCharger/NewConnectors';

interface EditChargerConnectorsFormProps {
  evses: CpoEvse[];
  handleCancel: () => void;
  handleSubmit: (values: CpoConnectors) => void;
  hasFailed: boolean;
}

export const EditChargerConnectorsForm: FC<EditChargerConnectorsFormProps> = ({ evses, handleCancel, handleSubmit, hasFailed }) => {
  const formikRef = createRef<FormikProps<CpoConnectors>>();
  useEffect(() => {
    if (hasFailed && formikRef.current) {
      formikRef.current.setSubmitting(false);
    }
  }, [hasFailed, formikRef]);

  const initialValues: CpoConnectors = {
    connectors: evses.flatMap((evse) =>
      evse.connectors
        .sort((a, b) => a.ocppConnectorId - b.ocppConnectorId)
        .flatMap((connector) => {
          const maxPower = connector.maxVoltage * connector.maxAmperage;
          return {
            ...connector,
            maxElectricPower: maxPower
          };
        })
    )
  };

  const validationSchema = Yup.object({
    connectors: Yup.array().of(
      Yup.object({
        ocppConnectorId: Yup.number().typeError('Should be a number').required('Required'),
        format: Yup.string().required('Required'),
        maxAmperage: Yup.number().typeError('Should be a number').required('Required'),
        maxVoltage: Yup.number().typeError('Should be a number').required('Required'),
        powerType: Yup.string().required('Required'),
        standard: Yup.string().required('Required')
      })
    )
  });

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Form>
          <NewConnectors
            formikRef={formikRef}
            bottomActionButtons={
              <>
                <CancelButton handleCancel={handleCancel} />
                <UpdateButton formikProps={props} />
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
};
